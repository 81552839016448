import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Typography, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const OrderManagement = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Charger les commandes au démarrage, y compris les infos utilisateur
  const fetchOrders = () => {
    axios.get('https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getOrders')
      .then(async (response) => {
        const ordersWithUserDetails = await Promise.all(
          response.data.map(async (order) => {
            const userResponse = await axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getUser&user_id=${order.user_id}`);
            return { ...order, user: userResponse.data };
          })
        );
        const sortedOrders = ordersWithUserDetails.sort((a, b) => new Date(b.dateCreation) - new Date(a.dateCreation));
        setOrders(sortedOrders);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des commandes:', error);
      });
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  // Mettre à jour le statut d'une commande
  const handleStatusChange = (cmd_increment, newStatus) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=updateOrderStatus&order_id=${cmd_increment}&status=${newStatus}`)
      .then((response) => {
        const cleanResponse = response.data.split('}{').join('},{');
        const parsedResponse = JSON.parse(`[${cleanResponse}]`);
  
        const statusUpdateResponse = parsedResponse[0];
        if (statusUpdateResponse.success) {
          console.log('Statut mis à jour avec succès:', statusUpdateResponse.message);
          setOrders(prevOrders => 
            prevOrders.map(order => 
              order.cmd_increment === cmd_increment ? { ...order, status: newStatus } : order
            )
          );
        } else {
          console.error('Erreur lors de la mise à jour du statut:', statusUpdateResponse.message);
        }
      })
      .catch((error) => {
        console.error('Erreur lors de la mise à jour du statut ou de la récupération des détails:', error);
      });
  };

  // Charger les détails d'une commande et ouvrir la modal
  const handleViewDetails = (orderId) => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getOrderDetails&order_id=${orderId}`)
      .then((response) => {
        setSelectedOrder(response.data);
        setIsModalOpen(true);

        return axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getPayment&order_id=${orderId}`);
      })
      .then((response) => {
        setPaymentDetails(response.data);
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des détails:', error);
      });
  };

  // Fermer la modal des détails de la commande
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedOrder(null);
    setPaymentDetails(null);
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h1" gutterBottom>Gestion des Commandes</Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID Commande</TableCell>
            <TableCell>Utilisateur</TableCell>
            <TableCell>Statut</TableCell>
            <TableCell>Total TTC</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(order => (
            <TableRow key={order.id}>
              <TableCell>{order.cmd_increment}</TableCell>
              <TableCell>
                <Typography variant="body2"><strong>Nom :</strong> {order.user.name}</Typography>
                <Typography variant="body2"><strong>Email :</strong> {order.user.email}</Typography>
                <Typography variant="body2"><strong>Adresse :</strong> {order.user.address}, {order.user.city}, {order.user.postal_code}, {order.user.country}</Typography>
              </TableCell>
              <TableCell>
                <Select
                  value={order.status}
                  onChange={(e) => handleStatusChange(order.cmd_increment, e.target.value)}
                >
                  <MenuItem value="pending">En attente</MenuItem>
                  <MenuItem value="completed">Terminée</MenuItem>
                  <MenuItem value="cancelled">Annulée</MenuItem>
                  <MenuItem value="aborted">Abandonnée</MenuItem>
                </Select>
              </TableCell>
              <TableCell>{order.total_ttc} €</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleViewDetails(order.id)}
                >
                  Détails
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal pour les détails de la commande */}
      <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>Détails de la commande {selectedOrder?.cmd_increment}</DialogTitle>
        <DialogContent>
          {selectedOrder && (
            <>
              <Typography variant="body1"><strong>Statut :</strong> {selectedOrder.status}</Typography>
              <Typography variant="body1"><strong>Total TTC :</strong> {selectedOrder.total_ttc} €</Typography>

              {paymentDetails && (
                <>
                  <Typography variant="h6" gutterBottom>Détails du paiement</Typography>
                  <Typography variant="body1"><strong>Méthode de paiement :</strong> {paymentDetails.payment_method}</Typography>
                  <Typography variant="body1"><strong>Montant :</strong> {paymentDetails.amount} €</Typography>
                  <Typography variant="body1"><strong>Transaction ID :</strong> {paymentDetails.transaction_id}</Typography>
                  <Typography variant="body1"><strong>Statut du paiement :</strong> {paymentDetails.payment_status}</Typography>
                </>
              )}

              {selectedOrder.cards && selectedOrder.cards.length > 0 && (
                <>
                  <Typography variant="h6" gutterBottom>Détails des Cartes</Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID Carte</TableCell>
                        <TableCell>Client</TableCell>
                        <TableCell>Visuel</TableCell>
                        <TableCell>Message</TableCell>
                        <TableCell>Titre</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedOrder.cards.map((card, index) => (
                        <TableRow key={index}>
                          <TableCell>{card.id}</TableCell>
                          <TableCell>{card.client}</TableCell>
                          <TableCell>{card.visuel}</TableCell>
                          <TableCell>{card.message}</TableCell>
                          <TableCell>{card.titre}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="primary"
                              href={`/edit-card/${selectedOrder.id}/${card.id}`}
                            >
                              Modifier
                            </Button>
                            </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              href={`/voir-ma-carte/${card.id}`}
                            >
                              Visuel
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="secondary">Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OrderManagement;
