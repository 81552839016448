import React, { useContext } from 'react';
import Typography from '@mui/material/Typography'; // Assurez-vous que Typography est importé correctement
import CheckoutForm from './CheckoutForm'; // Assurez-vous que CheckoutForm est bien importé
import { CartContext } from '../context/CartContext';

const Checkout = () => {
  const { cart } = useContext(CartContext);

  // Si le panier est vide, afficher un message ou rediriger
  if (cart.length === 0) {
    return <Typography variant="h5">Votre panier est vide.</Typography>;
  }

  return (
    <div className="container my-5 checkout-page">
      <Typography variant="h2">Paiement</Typography>
      {cart.length > 0 && <CheckoutForm />} {/* Afficher le formulaire de paiement ici */}
    </div>
  );
};

export default Checkout;
