import React, { useRef, useEffect, useState, useCallback } from 'react';
import './ScratchCard.css';

const ScratchCard = ({ width, height, imageSrc, gratteSrc, brushSize, text, textSize, textColor, marginTop, lineHeight, textHeight, gratte }) => {
  const canvasRef = useRef(null);
  const gratteRef = useRef(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [scratchedPercent, setScratchedPercent] = useState(0);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const image = gratteRef.current;
    image.crossOrigin = 'anonymous';
    image.onload = () => {
      context.drawImage(image, 0, 0);
      context.globalCompositeOperation = 'destination-out'; // Permet le grattage
    };
  }, [gratteSrc]);

  // Calculer le pourcentage gratté
  const calculateScratchedPercent = useCallback(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const pixels = context.getImageData(0, 0, width, height).data;
    const totalPixels = width * height;
    let scratchedPixels = 0;

    for (let i = 0; i < pixels.length; i += 4) {
      if (pixels[i + 3] === 0) {
        scratchedPixels++;
      }
    }

    setScratchedPercent((scratchedPixels / totalPixels) * 100);
  }, [width, height]);

  const handleMouseDown = () => {
    setIsMouseDown(true);
  };

  const handleMouseUp = useCallback(() => {
    if (isMouseDown) {
      setIsMouseDown(false);
      calculateScratchedPercent();
    }
  }, [isMouseDown, calculateScratchedPercent]);

  const handleMouseMove = (e) => {
    if (!isMouseDown) return;
    scratch(e.clientX, e.clientY);
  };

  // Gérer les événements tactiles
  const handleTouchStart = (e) => {
    setIsMouseDown(true);
    const touch = e.touches[0];
    scratch(touch.clientX, touch.clientY);
  };

  const handleTouchMove = (e) => {
    if (!isMouseDown) return;
    const touch = e.touches[0];
    scratch(touch.clientX, touch.clientY);
  };

  const handleTouchEnd = () => {
    if (isMouseDown) {
      setIsMouseDown(false);
      calculateScratchedPercent();
    }
  };

  const scratch = (x, y) => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const offsetX = x - rect.left;
    const offsetY = y - rect.top;

    context.beginPath();
    context.arc(offsetX, offsetY, brushSize, 0, Math.PI * 2, true);
    context.fill();
  };

  // Ajuster le marginTop du canvas si "gratte" contient le mot "rond"
  const adjustedMarginTop = gratteSrc.includes('rond') ? marginTop - 30 : marginTop;

  return (
    <div className="scratch-card-container">
      <div
        className="mobile-container"
        style={{ backgroundImage: `url(${imageSrc})` }}  // Utilisation de imageSrc comme fond
      >
        <div className="scratch-card-canvas-container" style={{ marginTop: `${adjustedMarginTop}px` }}>
          <canvas
            ref={canvasRef}
            width={width}
            height={height}
            className="scratch-card-canvas"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}   // Gérer l'interaction tactile
            onTouchMove={handleTouchMove}     // Gérer l'interaction tactile
            onTouchEnd={handleTouchEnd}       // Gérer l'interaction tactile
            style={{ touchAction: 'none' }}   // Empêche le défilement pendant le grattage sur mobile
          />
          <img
            ref={gratteRef}
            src={gratteSrc}
            alt="gratte"
            style={{ display: 'none' }}
          />
        </div>

        <div
          className="scratch-card-text"
          style={{
            fontSize: `${textSize}px`,
            color: textColor,
            marginTop: `${Number(marginTop) + Number(textHeight)}px`,
            lineHeight: `${lineHeight}px` // Interligne en pixels
          }}
        >
          {/* Convertir les \n en <br /> et interpréter comme du HTML */}
          <p dangerouslySetInnerHTML={{ __html: text.replace(/\n/g, '<br />') }} />
        </div>
      </div>
    </div>
  );
};

export default ScratchCard;
