import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Breadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);
  const [productName, setProductName] = useState('');
  const [mainCategory, setMainCategory] = useState({ name: '', slug: '' });

  useEffect(() => {
  const lastPath = pathnames[pathnames.length - 1];
  if (!isNaN(lastPath)) {
    axios.get(`https://back.atoneo.com/api/joli-mot-prods/${lastPath}?populate=joli_mot_categories`)
      .then(response => {
        if (response.data && response.data.data) {
          const productData = response.data.data.attributes;
          if (productData) {
            setProductName(productData.Titre);
            const primaryCategory = productData.joli_mot_categories?.data[0]?.attributes;
            if (primaryCategory) {
              setMainCategory({
                name: primaryCategory.categoryName,
                slug: primaryCategory.idCateg, // Le slug de la catégorie
              });
            }
          }
        } else {
          console.error('Aucune donnée trouvée');
        }
      })
      .catch(error => {
        console.error('Erreur lors du chargement du produit:', error.response ? error.response.data : error.message);
      });
  }
}, [pathnames]);


  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: "15px", paddingBottom: 0 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/" style={{ textDecoration: 'none', color: '#c48f8b' }}>
          Accueil
        </Link>
        {mainCategory.name && (
          <Link to={`/category/${mainCategory.slug}`} style={{ textDecoration: 'none', color: '#c48f8b' }}>
            {mainCategory.name}
          </Link>
        )}
        {productName && (
          <Typography color="textPrimary">
            {productName}
          </Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};

export default Breadcrumb;
