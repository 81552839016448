import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, TextField, Typography, Grid, InputLabel } from '@mui/material';

const EditCard = () => {
  const { cardId } = useParams();  // Récupère cardId depuis l'URL
  const [cardDetails, setCardDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Charger les détails de la carte
  useEffect(() => {
    axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=getCardDetails&card_id=${cardId}`)
      .then((response) => {
        if (response.data.success === false) {
          console.error('Erreur:', response.data.message);
        } else {
          console.log("Détails de la carte chargés :", response.data);  // Log pour vérifier la réponse
          setCardDetails(response.data); // Mettre à jour l'état avec les données de la carte
        }
      })
      .catch((error) => {
        console.error('Erreur lors du chargement des détails de la carte:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cardId]);

  // Gérer la modification des champs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  // Fonction pour convertir les données du formulaire en paramètres de requête GET
  const convertToQueryParams = (details) => {
    const queryParams = new URLSearchParams();
    queryParams.append('id', cardId);  // Utiliser directement cardId ici
    queryParams.append('client', details.client || '');
    queryParams.append('visuel', details.visuel || '');
    queryParams.append('message', details.message || '');
    queryParams.append('image', details.image || '');
    queryParams.append('gratte', details.gratte || '');
    queryParams.append('marginTop', details.marginTop || 0);
    queryParams.append('textMarginTop', details['text-margin-top'] || 0);
    queryParams.append('lineHeight', details['line-height'] || 0);
    queryParams.append('theme', details.theme || '');
    queryParams.append('titre', details.titre || '');
    queryParams.append('tailleTitre', details.taille_titre || 0);
    queryParams.append('couleurText', details.couleur_text || '#000000');
    queryParams.append('tailleText', details.taille_text || 0);
    queryParams.append('dateCreation', details.dateCreation || '');
    return queryParams.toString();
  };

  // Sauvegarder les modifications
  const handleSave = () => {
    if (cardDetails) {  // Vérifiez que les détails sont présents
      console.log("ID de la carte à mettre à jour:", cardId);  // Ajout d'un log pour vérifier l'ID utilisé
      const queryParams = convertToQueryParams(cardDetails);

      axios.get(`https://joli-mot.fr/apiEtsy.php?cleApi=34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75&service=modifCardAdmin&${queryParams}`)
        .then((response) => {
          if (response.data.success) {
            navigate(`/gestion-commandes`); // Redirection après la sauvegarde
          } else {
            console.error('Erreur lors de la sauvegarde:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la sauvegarde:', error);
        });
    } else {
      console.error('Détails de la carte manquants');
    }
  };

  if (loading) {
    return <Typography variant="h6">Chargement des détails de la carte...</Typography>;
  }

  if (!cardDetails) {
    return <Typography variant="h6" color="error">Erreur de chargement ou carte non trouvée</Typography>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h1" gutterBottom>Modifier la Carte</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Visuel"
            name="visuel"
            value={cardDetails.visuel || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Message"
            name="message"
            value={cardDetails.message || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Image"
            name="image"
            value={cardDetails.image || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Gratte"
            name="gratte"
            value={cardDetails.gratte || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Titre"
            name="titre"
            value={cardDetails.titre || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Thème"
            name="theme"
            value={cardDetails.theme || ''}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Taille du Titre"
            name="taille_titre"
            value={cardDetails.taille_titre || ''}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>Couleur du Texte</InputLabel>
          <input
            type="color"
            name="couleur_text"
            value={cardDetails.couleur_text || '#000000'}
            onChange={handleChange}
            style={{ width: '100%', height: '40px', padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Taille du Texte"
            name="taille_text"
            value={cardDetails.taille_text || ''}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Hauteur de Ligne"
            name="line-height"
            value={cardDetails['line-height'] || ''}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Marge Supérieure Texte"
            name="text-margin-top"
            value={cardDetails['text-margin-top'] || ''}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Marge Supérieure Gratte"
            name="marginTop"
            value={cardDetails.marginTop || ''}
            onChange={handleChange}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Sauvegarder
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default EditCard;
