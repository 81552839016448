import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import './ModifCartePanel.css';

const ModifCartePanel = ({
  textSize, setTextSize, textColor, setTextColor,
  message, setMessage,
  lineHeight, setLineHeight, textHeight, setTextHeight,
  onSubmit, isSubmitting, buttonText
}) => {

  // Fonction pour convertir les <br /> en \n lors du changement du textarea
  const handleMessageChange = (e) => {
    setMessage(e.target.value);  // Stocke directement le texte avec des sauts de ligne \n
  };

  return (
    <div className="modif-carte-panel">
      <h3>Modification de votre Carte</h3>
      <p className="info">
      <InfoOutlinedIcon fontSize="small" />
        Ou laissez-la telle quelle, si elle vous convient :)</p>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="textSize">Taille du texte: {textSize}px</label>
          <input
            id="textSize"
            type="range"
            value={textSize}
            onChange={(e) => setTextSize(Number(e.target.value))}
            min="10"
            max="100"
            step="1"
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="textColor">Couleur du texte:</label>
          <input
            id="textColor"
            type="color"
            value={textColor}
            onChange={(e) => setTextColor(e.target.value)}
            className="form-control"
          />
        </div>

        {/* Utilisation d'un textarea pour le message */}
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <p className="alerte">
            <WarningAmberOutlinedIcon fontSize="small" />
            Attention seuls certains emojis fonctionnent !</p>
          <textarea
            id="message"
            value={message.replace(/<br\s*\/?>/g, '\n')}  // Remplacer les <br /> par \n pour afficher dans textarea
            onChange={handleMessageChange}  // Utilisation de handleMessageChange pour mettre à jour le message
            className="form-control"
            rows="4"  // Hauteur du textarea (4 lignes visibles)
          />
        </div>

        <div className="form-group">
          <label htmlFor="textHeight">Hauteur du texte: {textHeight}</label>
          <input
            id="textHeight"
            type="range"
            value={textHeight}
            onChange={(e) => setTextHeight(Number(e.target.value))}
            min="-100"
            max="100"
            step="1"
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="lineHeight">Interligne: {lineHeight}</label>
          <input
            id="lineHeight"
            type="range"
            value={lineHeight}
            onChange={(e) => setLineHeight(Number(e.target.value))}
            min="0"
            max="100"
            step="0.1"
            className="form-control"
          />
        </div>

        <button type="submit" className="submit-button" disabled={isSubmitting}>
          {buttonText}
        </button>
      </form>
    </div>
  );
};

export default ModifCartePanel;
