// src/components/LabelEtiquette.js
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PregnantWomanIcon from '@mui/icons-material/PregnantWoman';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ForestIcon from '@mui/icons-material/Forest';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import StarIcon from '@mui/icons-material/Star';

export const getLabelProperties = (label) => {
    const labelIcons = {
        'Nouveauté': { backgroundColor: '#A8D5BA', icon: <NewReleasesIcon style={{ color: '#FFFFFF' }} /> },
        'Promo': { backgroundColor: '#FFCCCB', icon: <LocalOfferIcon style={{ color: '#FFFFFF' }} /> },
        'Grossesse': { backgroundColor: '#ADD8E6', icon: <PregnantWomanIcon style={{ color: '#FFFFFF' }} /> },
        'Faire-part': { backgroundColor: '#FFFACD', icon: <CardGiftcardIcon style={{ color: '#FFFFFF' }} /> },
        'Demande témoins': { backgroundColor: '#FFE4E1', icon: <EmojiPeopleIcon style={{ color: '#FFFFFF' }} /> },
        'Noël': { backgroundColor: '#457947', icon: <ForestIcon style={{ color: '#FFFFFF' }} /> },
        'Mariage': { backgroundColor: '#F8BBD0', icon: <FavoriteIcon style={{ color: '#FFFFFF' }} /> },
        'Naissance': { backgroundColor: '#BBDEFB', icon: <ChildCareIcon style={{ color: '#FFFFFF' }} /> },
    };

    // Retourne les propriétés correspondantes ou par défaut
    return labelIcons[label] || { backgroundColor: '#E0E0E0', icon: <StarIcon style={{ color: '#FFFFFF' }} /> };
};
