import React, { useContext, useState } from 'react';
import { CartContext } from '../context/CartContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const Cart = () => {
  const { cart, removeFromCart, updateCartItemQuantity, promoCode, setPromoCode, discount, setDiscount } = useContext(CartContext);
  const TVA_RATE = 0.2; 

  const [promoInput, setPromoInput] = useState('');
  const [promoApplied, setPromoApplied] = useState(false);
  const [promoError, setPromoError] = useState(null);
  const [loading, setLoading] = useState(false); 

  const totalHT = cart.reduce((acc, product) => acc + (product.price / 100) * product.quantity, 0);
  const totalTVA = totalHT * TVA_RATE;
  const totalTTC = totalHT + totalTVA - (typeof discount === 'number' ? discount : 0); 

  const handleQuantityChange = (index, newQuantity) => {
    if (newQuantity < 1) return;
    updateCartItemQuantity(index, newQuantity);
  };

  const handlePromoCodeApply = async () => {
    setLoading(true);
    setPromoError(null);

    try {
      const response = await axios.get('https://joli-mot.fr/apiEtsy.php', {
        params: {
          cleApi: "34KVHFQSP226YTk4n256QP6rGbqhA5w2ymY7W3pLwdbuc3i75",
          service: 'checkPromoCode',
          promoCode: promoInput,
        },
      });

      const { discount_type, discount_value } = response.data;

      setPromoCode(promoInput);
      if (discount_type === 'percentage') {
        setDiscount(totalHT * (discount_value / 100));
      } else if (discount_type === 'fixed') {
        setDiscount(parseFloat(discount_value));
      }

      setPromoApplied(true);
    } catch (error) {
      setPromoError('Code promo invalide ou expiré.');
      setDiscount(0);
    } finally {
      setLoading(false);
    }
  };

  const handleRemovePromo = () => {
    setPromoCode('');
    setDiscount(0);   
    setPromoApplied(false); 
    setPromoError(null);
  };

  if (cart.length === 0) {
    return <Typography variant="h5" align="center" sx={{ mt: 5 }}>Votre panier est vide.</Typography>;
  }

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h1" sx={{ marginBottom: 3, color: '#c48f8b' }}>Votre Panier</Typography>
      <Grid container spacing={3}>
        {cart.map((product, index) => (
          <Grid item xs={12} key={index} sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`https://back.atoneo.com${product.selectedVisual}`}
              alt={product.name}
              style={{ width: '100px', marginRight: '20px' }}
            />
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6">{product.name}</Typography>
              {product.customText && (
                <Typography variant="body2" color="text.secondary">
                  Personnalisation: {product.customText}
                </Typography>
              )}
            </Box>
            <TextField
              label="Quantité"
              type="number"
              value={product.quantity}
              onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
              sx={{ width: '100px', marginRight: '20px' }}
              inputProps={{ min: 1 }}
            />
            <Typography variant="body1">{((product.price / 100) * product.quantity).toFixed(2)} €</Typography>
            <Button variant="contained" color="error" onClick={() => removeFromCart(index)} sx={{ marginLeft: '20px' }}>
              Supprimer
            </Button>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ marginY: 3 }} />

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: 2 }}>
        <TextField
          label="Code Promo"
          value={promoInput}
          onChange={(e) => setPromoInput(e.target.value)}
          variant="outlined"
          sx={{ marginRight: 2 }}
          disabled={promoApplied}
        />
        <Button
          variant="contained"
          color="secondary"
          onClick={handlePromoCodeApply}
          disabled={promoApplied || loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Appliquer'}
        </Button>
      </Box>

      {promoError && <Alert severity="error">{promoError}</Alert>}

      {discount > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 2 }}>
          <Typography variant="h6" color="green">
            Réduction: -{discount.toFixed(2)} €
          </Typography>
          <Button
            variant="text"
            color="primary"
            onClick={handleRemovePromo}
            sx={{ marginLeft: 2 }}
          >
            Supprimer le code promo
          </Button>
        </Box>
      )}

      <Typography variant="h6">Total HT: {totalHT.toFixed(2)} €</Typography>
      <Typography variant="h6">TVA ({TVA_RATE * 100}%): {totalTVA.toFixed(2)} €</Typography>
      <Typography variant="h5" sx={{ marginTop: 2 }}>Total TTC: {totalTTC.toFixed(2)} €</Typography>

      <Button variant="contained" color="primary" component={Link} to="/checkout" sx={{ marginTop: 3 }}>
        Procéder au paiement
      </Button>
    </div>
  );
};

export default Cart;
