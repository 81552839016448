import React, { createContext, useState } from 'react';

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);          
  const [promoCode, setPromoCode] = useState(''); 
  const [discount, setDiscount] = useState(0);    

  const addToCart = (product) => {
    setCart((prevCart) => [...prevCart, { ...product, quantity: 1 }]);
  };

  const removeFromCart = (index) => {
    setCart((prevCart) => prevCart.filter((_, i) => i !== index));
  };

  const updateCartItemQuantity = (index, newQuantity) => {
    setCart((prevCart) => {
      const updatedCart = [...prevCart];
      updatedCart[index].quantity = newQuantity;
      return updatedCart;
    });
  };

  const clearPromoCode = () => {
    setPromoCode('');
    setDiscount(0);
  };

  return (
    <CartContext.Provider value={{
      cart, addToCart, removeFromCart, updateCartItemQuantity,
      promoCode, setPromoCode, discount, setDiscount, clearPromoCode
    }}>
      {children}
    </CartContext.Provider>
  );
};
